.file-upload {
  border-color: rgb(207, 206, 206) !important;
  height: 100% !important;
  min-width: unset !important;
}

.file-upload svg path {
  fill: rgb(206, 206, 206);
}

.file-upload__label{
    margin-bottom: 5px;
}

.image-preview{
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
}
