.color-picker__label {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.6px;
  display: block;
  margin-bottom: 5px;
}

.color-picker__picker {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  border: none;
  overflow: hidden;
  border-radius: 6px;
  margin-right: 6px;
}

.color-picker__text-input {
  padding: 1rem;
  border: none;
  background-color: rgb(226, 226, 226);
  border-radius: 6px;
}
