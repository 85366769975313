@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

body .container {
  width: 100%;
  max-width: 1170px;
}

body img {
  max-width: 100%;
}

section,
header,
footer {
  float: left;
  width: 100%;
}

a {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  cursor: pointer !important;
  display: inline-block;
  text-decoration: none !important;
}

button,
input[type="submit"] {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
input,
button,
select {
  outline: none;
}
.custom-scroll1::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.custom-scroll1::-webkit-scrollbar {
  width: 8px;
  background-color: #00b22d;
}

.custom-scroll1::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

.col-white {
  color: white !important;
}
.col-black {
  color: black !important;
}
.col-orange {
  color: #fd612f !important;
}

.col-grey {
  color: grey !important;
}
.col-silver {
  color: silver !important;
}
.col-silver2 {
  color: #a3a3a3 !important;
}

.bg-white {
  background: white !important;
}

.bg-black {
  background: black !important;
}

.pad-top-20 {
  padding-top: 20px !important;
}
.pad-top-40 {
  padding-top: 40px !important;
}
.pad-top-60 {
  padding-top: 60px !important;
}
.pad-top-80 {
  padding-top: 80px !important;
}

.pad-bot-20 {
  padding-bottom: 20px !important;
}
.pad-bot-40 {
  padding-bottom: 40px !important;
}
.pad-bot-60 {
  padding-bottom: 60px !important;
}
.pad-bot-80 {
  padding-bottom: 80px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}

.no-margin {
  margin: 0px !important;
}
.no-pad {
  padding: 0px !important;
}

section.bg-1 {
  background-size: cover;
  min-height: 100vh;
  background-position: top left;
}

.custom-container1 {
  max-width: 400px !important;
  margin: 130px auto 100px auto;
}

.registration-forms-page {
  position: relative;
  background: linear-gradient(110deg, #000000 70%, #ff643a 300%);
  min-height: 100vh;
}

.registration-forms-page .logo1 {
  position: absolute;
  width: 110px;
  top: 2.5%;
  left: 2.5%;
}

.registration-forms-page .submit-btn5,
.welcome-page .submit-btn5,
.plans-page .submit-btn5 {
  background: transparent
    linear-gradient(97deg, #ff643a 0%, #ff643ad4 45%, #ff643a8c 100%);
  border: none;
  width: 100%;
  height: 55px;
  padding: 12px 0px;
  letter-spacing: 0px;
  font-size: 18px;
  line-height: 21px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  border-radius: 10px;
}

.plans-page .submit-btn5 {
  padding: 14px 28px;
  width: unset;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
}

.registration-forms-page .submit-btn5:hover,
.welcome-page .submit-btn5:hover,
.plans-page .submit-btn5:hover {
  box-shadow: none;
  background: transparent
    linear-gradient(97deg, #ff643a 0%, #ff643ad4 45%, #ff643a8c 100%);
}

.welcome-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.welcome-page .logo1 {
  position: absolute;
  width: 110px;
  top: 2.5%;
  left: 2.5%;
}

.welcome-page__body {
  width: 90%;
  max-width: 460px;
  padding-top: 200px;
  margin: auto;
  text-align: center;
}

.welcome-page__title {
  font-size: 40px;
  line-height: 47px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #222222;
  text-transform: uppercase;
}

.welcome-page__paragraph {
  font: normal normal normal 14px/16px Raleway;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #7c7c7c;
  margin-top: 10px;
  margin-bottom: 20px;
}

.custom-container2 {
  max-width: 700px !important;
  margin: 130px auto 80px auto;
}

.logo1 {
  margin-bottom: 35px;
}

.logo1 h1 {
  font-weight: 700;
  font-size: 38px;
  letter-spacing: 1px;
}

.sec-head1 {
  margin-bottom: 40px;
  max-width: 300px;
  margin: auto;
}

.sec-head1 h3 {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 37px;
  margin-bottom: 15px;
  text-align: center;
}

.sec-p1 {
  color: #7c7c7c;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}

.form-field1 {
  margin-bottom: 15px;
}

input.field-style1::placeholder {
  color: #7c7c7c !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.field-style1 {
  background-color: #222222;
  border-radius: 10px;
  color: white !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  border: none;
  height: 55px;
  width: 100%;
  font-weight: normal;
  padding: 0px 22px;
}

.form-field-submit {
  position: relative;
}

.form-field-submit p {
  margin: 0px;
}

.form-field-submit label {
  margin: 0px;
  cursor: pointer;
}

.form-field-submit label {
  margin: 0px;
  color: #9d9d9d;
  letter-spacing: 0.4px;
  vertical-align: middle;
  font-size: 15px;
  margin-top: 10px;
}
.form-field-submit label input {
  display: inline-block;
  margin-right: 5px;
}

.login-btn1 {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #f2563f;
  text-align: left;
  border: none;
  color: white;
  border-radius: 30px;
  padding: 8px 20px;
  min-width: 150px;
  font-size: 16px;
  letter-spacing: 1px;
  box-shadow: 1px 1px 10px #ffbeb4;
}
.login-btn1 i {
  float: right;
  margin-top: 4px;
  font-size: 14px;
}

.form-1 {
  text-align: center;
}

.login-btn1:hover {
  background: #fd612f !important;
}

.login-btn2:hover {
  background: #fd612f !important;
}

.forgot-pass {
  float: right;
  margin-top: -18px;
  color: grey;
  letter-spacing: 0.4px;
}

.forgot-password-submit {
  margin-top: 20px;
}

.forgot-password-back,
.forgot-password-back:hover,
.forgot-password-back:link,
.forgot-password-back:active {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #ff643a;
  text-transform: capitalize;
  margin-top: 20px;
}

.forgot-password-back-icon {
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;
  margin-right: 5px;
}

.reset-password-hint {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 20px;
}

.login-btn2 {
  float: right;
  background: #f2563f;
  text-align: left;
  border: none;
  color: white;
  border-radius: 30px;
  padding: 8px 20px;
  min-width: 150px;
  font-size: 16px;
  letter-spacing: 1px;
  box-shadow: 1px 1px 10px #ffbeb4;
}
.login-btn2 i {
  float: right;
  margin-top: 4px;
  font-size: 14px;
}

section.left-sidebar {
  background: #222222;
  width: 20%;
  height: 100vh;
  padding: 0px 0px;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;
  display: flex;
  flex-direction: column;
}

.logo-2 {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 35px;
  padding: 0px 22px;
}
.logo-2 h1 {
  color: white;
  text-align: center;
  font-size: 30px;
  letter-spacing: 1px;
}

.sidebar-menu {
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.sidebar-menu ul {
  padding: 0px;
  list-style: none;
}

.sidebar-menu ul li {
  margin: 0 22px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}

.sidebar-menu ul li a {
  transition: none;
}

.sidebar-menu ul li:hover {
  background-color: rgba(256, 256, 256, 0.1);
}
.sidebar-menu ul li:hover a {
  color: #ff643a;
}
.sidebar-menu ul li:hover .sidebar-icon path {
  fill: #ff643a;
}
.sidebar-menu ul li:hover .sidebar-icon1 path,
.sidebar-menu ul li:hover .sidebar-icon1 line {
  stroke: #ff643a;
}
.sidebar-menu ul .active {
  background-color: rgba(256, 256, 256, 0.1);
}
.sidebar-menu ul .active .sidebar-icon path {
  fill: #ff643a;
}
.sidebar-menu ul .active .sidebar-icon1 path,
.sidebar-menu ul .active .sidebar-icon1 line {
  stroke: #ff643a;
}
.sidebar-menu ul .active a {
  color: #ff643a;
}
.sidebar-menu ul li a {
  font-size: 14px;
  font-weight: normal;
  padding: 13px 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
}

.sidebar-menu ul li {
  margin-bottom: 15px;
}

.sidebar-menu ul li a img {
  width: 14px;
  margin-right: 12px;
  vertical-align: middle;
}
.sidebar-icon {
  width: 14px;
  height: 14px;
  margin-right: 12px;
}
.sidebar-icon path {
  fill: white;
}
.sidebar-icon1 path,
.sidebar-icon1 line {
  stroke: white;
}

.sidebar-logout {
  transform: rotate(180deg);
  margin-right: 12px;
}
.sidebar-logout path {
  fill: #ff643a;
}
.sidebar-menu__tab-title {
  margin: 22px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0px;
  color: #7c7c7c;
  text-transform: uppercase;
}
.logout-wrapper {
  width: 100%;
}

.logout-btn {
  font-size: 12px;
  letter-spacing: 0px;
  color: #ff643a;
  text-transform: capitalize;
  margin: 0 22px;
  margin-bottom: 15px;
  padding: 13px 10px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(256, 256, 256, 0.1);
}
.logout-btn img {
  margin-right: 5px;
}
.navbar-handler {
  display: none;
}

section.page-content {
  float: right;
  width: 80%;
  padding: 0px 30px;

  background-size: 100% 100%;
  min-height: 100vh;
}

.action-content {
  text-align: center;
  display: block;
  margin: 80px 0px 0px 0px;
}

.action-head {
  display: block;
  text-align: center;
  margin-bottom: 40px;
}

.action-head h3 {
  font-size: 35px;
  font-weight: 700;
  max-width: 400px;
  margin: auto;
  letter-spacing: 0.6px;
  font-weight: 500;
}
.action-options {
  display: flex;
  text-align: center;
  max-width: 600px;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
}
.action-options a {
  background: #fcfbfb;
  box-shadow: 1px 1px 6px #dddddd;
  padding: 15px 10px 15px 10px;
  height: auto;
  color: black;
  vertical-align: middle;
  border-radius: 40px;
  margin: 0px 15px 30px 15px;
  font-size: 22px;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-weight: 500;
  width: 230px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}
.action-options a span {
  font-size: 13px;
  line-height: normal;
  display: block;
  font-style: italic;
  letter-spacing: 0.4px;
  line-height: 21px;
  margin-top: 5px;
  font-weight: 500;
}

.action-options a b {
  display: block;
  width: 100%;
  font-weight: 700;
}
.action-options a img {
  height: 50px;
  margin: auto;
  margin-bottom: 4px;
}
.action-options a:hover {
  background: #ff643a;
  color: white;
}
.avatar-upload {
  position: relative;
  height: 100%;
  margin: 0;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: auto;
  z-index: 1;
  top: 3%;
  bottom: auto;
  left: 10%;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ff643a;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: black;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "+";
  color: white;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  font-size: 22px;
  font-weight: 500;
  width: 34px;
  height: 34px;
}
.avatar-upload .avatar-preview {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0px;
  border: 0px solid #f8f8f8;
  /*box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);*/
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.page-header {
  width: 100%;
  margin-bottom: 20px;
}

.custom-margin1 {
  margin-top: 100px;
}

.page-header h3 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.6px;
}

.profile-image-uploader {
  float: left;
  width: 100%;
  border-radius: 10px;
  /*box-shadow: 1px 1px 10px #dfdfdf;*/
  background: white;
}

.profile-details h4 {
  color: black;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-top: 30px;
  margin-bottom: 0px;
  font-size: 22px;
}

.profile-details h5 {
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 15px;
}

.custom-btn1 {
  background: #ff643a;
  padding: 5px 23px;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
  border-radius: 30px;
  font-size: 15px;
  margin: 0px 4px;
}

.custom-btn2 {
  background: #c0c0c0;
  padding: 5px 23px;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
  border-radius: 30px;
  font-size: 15px;
  margin: 0px 4px;
}

.profile-details a {
  padding: 5px 0px;
  width: 100px;
}

.about-form {
  width: 100%;
  max-width: 550px;
  margin-top: 30px;
}

.form-field2 {
  float: left;
  width: 100%;
  margin-bottom: 22px;
}

.form-field-submit2 {
  text-align: center;
  float: left;
  width: 100%;
}

.field-style2::placeholder {
  font-weight: 400;
  color: #bbbbbb;
}

.field-style2 {
  width: 100%;
  height: 40px;
  padding: 0px 5px;
  letter-spacing: 0.6px;
  border: none;
  font-size: 15px;
  color: black;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid #d9d9d9;
}

.form-field2 h6 {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  color: #9f9f9f;
  letter-spacing: 0.3px;
  font-weight: 500;
}

.submit-btn3 {
  background: #ff643a;
  border: none;
  color: white;
  font-size: 20px;
  padding: 8px 0px;
  letter-spacing: 0.6px;
  font-weight: 400;
  border-radius: 10px;
  /* box-shadow: 1px 1px 10px #ff6a56; */
  box-shadow: none;
  margin: 0px 10px 0px 0px;
  width: 210px;
}

.profile-btn1,
.profile-btn2 {
  cursor: pointer;
  user-select: none;
}

.submit-btn4 {
  background: black;
  border: none;
  color: white;
  font-size: 20px;
  padding: 8px 0px;
  letter-spacing: 0.6px;
  font-weight: 400;
  border-radius: 10px;
  box-shadow: none;
  margin: 0px 10px;
  width: 210px;
}

.submit-btn3:hover {
  background: black;
  box-shadow: 1px 1px 10px silver;
}

.submit-btn4:hover {
  background: black;
}

.saved-contacts-slider {
  float: left;
  width: 100% !important;
  padding: 0px 15px !important;
  position: relative;
}

.contact-box {
  padding: 0px 10px;
  margin: 10px 0px;
}
.contact-box > div {
  width: 100%;
  box-shadow: 0px 3px 10px #bbbbbb;
  text-align: center;
  padding: 20px 10px 10px 10px;
  background: white;
  border-radius: 5px;
}

.contact-box img {
  width: 150px;
  border-radius: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.contact-box h4 {
  letter-spacing: 0.4px;
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 20px;
}

.arrow-1 .slick-prev::before {
  content: "<";
}

.arrow-1 .slick-next::before {
  content: ">";
}

.arrow-1 button.slick-arrow {
  background: red;
  height: 28px;
  width: 28px;
  border-radius: 100%;
}
.arrow-1 button.slick-arrow::before {
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
  margin-top: 5px;
  display: inline-block;
}

.arrow-1 button.slick-prev.slick-arrow {
  left: -15px;
}

.arrow-1 button.slick-next.slick-arrow {
  right: -15px;
}

.custom-margin2 {
  margin-top: 150px;
}

.custom-form2 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.custom-form2-head h3 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.8px;
}

.custom-form2-head {
  margin-bottom: 60px;
}

.form-field3 {
  margin-bottom: 32px;
}

.form-field3 h6 {
  font-size: 16px;
  letter-spacing: 0.6px;
  font-weight: 500;
  margin-bottom: 6px;
}

.field-style4 {
  width: 100%;
  height: 35px;
  padding: 0px 0px;
  letter-spacing: 0.6px;
  color: #8f8f8f;
  border: none;
  background: none;
  font-size: 16px;
  border-bottom: 1px solid #d9d9d9;
}

.submit-btn5 {
  background: #ff643a;
  border: none;
  width: 100%;
  padding: 12px 0px;
  font-size: 18px;
  letter-spacing: 1px;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  /*box-shadow: 1px 1px 5px #f15f4b;*/
  border-radius: 10px;
}
.submit-btn5:hover {
  background: black;
  box-shadow: 1px 1px 10px silver;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fa-phone {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

section.preview-sec .container {
  max-width: 590px;
  margin: auto;
}

.block-element {
  float: left;
  width: 100%;
}

.preview-wrapper {
  float: left;
  width: 100%;
}

section.preview-sec {
  padding: 0px 0px;
}

.sec-head3 {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.sec-head3 h4 {
  color: black;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-size: 22px;
}

.contact-fields {
  float: left;
  width: 100%;
}

.contact-fields ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.contact-fields ul li a {
  color: black;
  font-size: 15px;
  letter-spacing: 0.6px;
  border-bottom: 1px solid #d7d7d7;
  display: block;
  padding: 12px 35px 12px 40px;
  position: relative;
  font-weight: 500;
}
.contact-fields ul li a svg {
  position: absolute;
  left: 6px;
  top: 30%;
}
.angle-icon1 {
  position: absolute;
  right: 0px !important;
  left: auto !important;
  color: #cdcdcd;
  font-size: 20px;
}

.links-fields ul li a img {
  width: 50px;
  position: absolute;
  left: 0px;
  top: 15%;
}

.links-fields ul li a {
  padding: 18px 35px 18px 70px !important;
  min-height: 65px !important;
}
.social-fields {
  float: left;
  width: 100%;
}
.social-fields ul {
  padding: 0px;
  list-style: none;
  margin-bottom: 0px !important;
}
.social-fields ul li a img {
  width: 30px;
}

.social-fields ul li {
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  margin-bottom: 10px;
}

.social-fields ul li a {
  background: #f5f5f5;
  padding: 0px;
  border-radius: 8px;
  color: grey;
  font-size: 22px;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-fields ul li a:hover {
  background: #eee;
}

.about-para {
  float: left;
  width: 100%;
}
.about-para p {
  color: black;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 26px;
}

.profile-buttons {
  float: left;
  width: 100%;
}

.profile-buttons {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.profile-btn1 {
  background: black;
  color: white;
  border: 1px solid #e4e4e4;
  font-weight: 600;
  border-radius: 10px;
  width: 48%;
  text-align: center;
  padding: 12px 0px;
  font-size: 16px;
  letter-spacing: 1px;
}
.profile-btn1:hover {
  background: black;
  color: white;
  border: 1px solid black;
}

.profile-btn2 {
  background: #ff643a;
  color: white;
  border: 1px solid #ff643a;
  font-weight: 600;
  border-radius: 10px;
  width: 48%;
  text-align: center;
  padding: 12px 0px;
  font-size: 16px;
  letter-spacing: 1px;
}
.profile-btn2:hover {
  background: #f65023;
  color: white !important;
}

.profile-logo {
  float: left;
}

.profile-logo h2 {
  margin: 0px;
  letter-spacing: 0.6px;
  font-size: 32px;
}

.profile-button {
  float: right;
}

.profile-button a {
  border: 1px solid white;
  color: white;
  font-size: 15px;
  letter-spacing: 0.7px;
  padding: 6px 16px;
  border-radius: 5px;
  font-weight: normal;
}

.profile-button a:hover {
  background: #ff643a;
  color: white;
}

.profile-header {
  float: left;
  width: 100%;
  background: black;
  padding: 20px 12px 200px 12px;
  border-radius: 8px 8px 0px 0px;
}

.profile-overview {
  float: left;
  width: 100%;
  background: black;
  border-radius: 15px;
  margin-bottom: 25px;
  margin-top: -180px;
  overflow: hidden;
}

.profile-preview-info {
  display: flex;
}

.profile-image5 {
  float: left;
  width: 40%;
}

.profile-image5 img {
  width: 100%;
}
.profile-name {
  float: left;
  width: 60%;
  padding-top: 60px;
  padding-left: 20px;
}

.profile-name h4 {
  color: white;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 55px;
  margin-bottom: 15px;
}

.profile-name h6 {
  color: silver;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  padding-right: 10px;
  margin: 10px 0;
}

.custom-pad2 {
  padding: 0px 0px;
}

.form-field5 {
  float: left;
  width: 100%;
  margin-bottom: 22px;
}

.field-style6 {
  width: 100%;
  height: 38px;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid #d5d5d5;
  color: black;
  letter-spacing: 0.4px;
}

span.info-tag1 {
  display: block;
  color: grey;
  font-size: 13px;
  letter-spacing: 0.3px;
  padding: 0px 5px;
}

.submit-btn6 {
  border: none;
  width: 100%;
  height: 50px;
  background: #ff643a;
  color: white;
  font-size: 16px;
  letter-spacing: 0.7px;
  font-weight: 500;
  border-radius: 5px;
}

.logo-2 > img {
  width: 84px;
}

.logo1 > img {
  width: 120px;
}

.profile-logo img {
  width: 100px;
}

button.more-btn1 {
  border: none;
  font-size: 13px;
  color: #8b8b8b;
  background: #eee;
  padding: 3px 10px;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.4px;
  margin-top: 8px;
}
.social-submit {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.add-social-btn {
  border: none;
  font-size: 18px;
  color: white;
  background: #ff643a;
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.more-fields {
  float: left;
  width: 100%;
  position: relative;
  padding-bottom: 20px;
}
.more-button {
  position: absolute;
  bottom: 6px;
}
a.forgot-pass6 {
  color: white;
  letter-spacing: 0px;
  font-weight: normal;
  padding: 0px 5px;
  margin-top: -17px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
}

.get-card-now p {
  color: white;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.inline-field1 {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px !important;
  margin-bottom: 15px;
}
.inline-field1 label {
  margin: 0px !important;
  color: black;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.inline-field1 input {
  width: 20px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.submit-btn4:hover {
  background: #ff643a;
}

.saved-box5 {
  position: relative;
  background: white;
  float: left;
  width: 100%;
  padding: 8px 12px;
  box-shadow: 0px 3px 9px silver;
  border-radius: 4px;
  max-width: 700px;
  margin-bottom: 30px;
}
.saved-box5 div:nth-child(1) {
  width: 40%;
  display: inline-block;
  vertical-align: top;
}
.saved-box5 div:nth-child(2) {
  width: 40%;
  display: inline-block;
  vertical-align: top;
}
.saved-box5 div:nth-child(3) {
  width: 18%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  margin-top: 8px;
}

.saved-box5 div h5 {
  color: black;
  font-size: 22px;
  letter-spacing: 0.5px;
  margin-top: 0px;
  margin-bottom: 5px;
}
.saved-box5 div span {
  display: block;
  color: black;
  letter-spacing: 0.6px;
  font-size: 15px;
  margin-bottom: 2px;
}
.saved-box5 div h6 {
  color: black;
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-top: 0px;
  margin-bottom: 5px;
}
.saved-box5 div:nth-child(2) {
  margin-top: 2px;
}
.social-fields ul li a:hover {
  background: #ff643a;
  color: white;
}

select.field-style2 {
  font-weight: 500;
  color: #9b9b9b;
  cursor: pointer;
}

.save-btn6 {
  background: #ff643a;
  border: none;
  color: white;
  font-size: 18px;
  padding: 7px 25px;
  letter-spacing: 0.6px;
  font-weight: 400;
  border-radius: 6px;
  font-weight: 700;
  text-decoration: none;
}
select.field-style2 option {
  color: #9b9b9b;
}

.dual-field .form-field2 .field-style2 {
  width: 65% !important;
}

.dual-field .form-field2 .field-style2:nth-child(2) {
  width: 150px !important;
}

.dual-field .form-field2 {
  justify-content: space-between;
  display: flex;
}

.custom-pad2 {
  padding: 0px 15px;
}

.add-btn1 {
  background: #7d7d7d;
  border: none;
  color: white;
  font-size: 15px;
  padding: 8px 30px;
  letter-spacing: 0.8px;
  font-weight: 400;
  border-radius: 10px;
  box-shadow: none;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.added-btn1 {
  background: #ff643a;
}
.get-card-now p a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ff643a;
  text-transform: capitalize;
  text-decoration: underline !important;
}
.sidebar-menu ul li a span {
  font-size: 13px;
  font-style: italic;
  display: inline-block;
}

.save-btn6:hover {
  background: black;
  color: white;
}

.search-bar form {
  position: relative;
  border: 1px solid silver;
  max-width: 450px;
  border-radius: 30px;
  overflow: hidden;
}

.search-bar form input {
  width: 100%;
  border: none;
  padding: 0px 20px;
  font-size: 16px;
  height: 40px;
  letter-spacing: 0.7px;
  color: black;
}
.search-bar button {
  position: absolute;
  right: 0px;
  top: 0px;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  background: #ff643a;
  color: white;
}

.contact-prof-title {
  width: 100%;
  background: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-content: center;
}

.field-style5::placeholder {
  color: white;
}
.field-style5 {
  background: none;
  border: none;
  font-size: 30px;
  color: white;
  width: 100%;
  display: block !important;
  letter-spacing: 1px;
  line-height: 40px;
  resize: none;
  margin-bottom: 8px;
  font-weight: 700;
  height: 90px;
  margin-top: auto;
}

.field-style7::placeholder {
  color: silver;
}
.field-style7 {
  background: none;
  border: none;
  font-size: 16px;
  color: silver;
  width: 100%;
  display: block !important;
  letter-spacing: 1px;
  line-height: 22px;
  resize: none;
  height: 75px;
}
.profile-wrapper2 {
  max-width: 500px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}

.profile-picture-wrapper {
  float: left;
  width: 100%;
}

.social-adder {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.social-adder-head {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.social-adder-head h3 {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.6px;
}

.social-adder-blocks {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.social-icon-block {
  background: #f5f5f5;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 12px;
}
.social-add-block {
  background: #f5f5f5;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 20px;
}

.social-add-block p {
  color: black;
  font-size: 30px;
  display: block;
  vertical-align: top;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  font-weight: 600;
  height: 100%;
  line-height: 60px;
}

p.close-btn4 {
  background: #adacac;
  font-size: 11px;
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  text-align: center;
  line-height: 18px;
  position: absolute;
  right: -6px;
  top: -6px;
  cursor: pointer;
}

.social-icon6 img {
  width: 25px;
}
.social-popup1 .modal-content {
  padding: 40px 20px;
}

.social-popup1 .modal-dialog {
  max-width: 550px;
  top: 50%;
  transform: translateY(-50%);
}

.close-btn2 {
  background: none;
  border: none;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 100%;
  background: black;
  color: white;
  line-height: 20px;
  letter-spacing: 0.8px;
  font-size: 12px;
}

a.social-icon6 {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 58px;
}
.side-bar-card {
  font-size: 12px;
  color: grey;
  padding: 0 22px;
}

.plans-page {
  position: relative;
  background: linear-gradient(110deg, #222222 70%, #ff643a 300%);
  min-height: 100vh;
  padding-top: 130px;
}

.plans-page .logo1 {
  position: absolute;
  width: 110px;
  top: 2.5%;
  left: 2.5%;
}

.plans-page__body {
  width: 100%;
  max-width: 500px;
  text-align: center;
  margin: auto;
  padding-bottom: 20px;
}

.plans-page__features-icon {
  margin: 20px 0;
}

.plans-page__header-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plans-page__title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.plans-page__paragraph {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  max-width: 480px;
  margin: auto;
  margin-bottom: 20px;
}

.plans-page__features-list {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.plans-page__feature-item-header {
  display: flex;
  color: #ff643a;
  align-items: center;
}

.plans-page__feature-item-title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-left: 20px;
}

.plans-page__feature-item-icon {
  width: 19px;
}

.plans-page__feature-item-icon path {
  fill: #ff643a;
}

.plans-page__feature-item-paragraph {
  text-align: left;
  margin-left: 39px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #222222;
}

.plans-page__feature-item-details {
  margin-bottom: 15px;
}

.plans-page__pricing {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
}

.plans-page__pricing-paragraph {
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #222222;
  text-transform: capitalize;
}

.plans-page__pricing-plan {
  margin-top: 15px;
  color: #ff643a;
}

.plans-page__pricing-value {
  font-size: 40px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: 0px;
}

.plans-page__pricing-duration {
  font-weight: 500;
}

.payment .modal-dialog {
  width: 100%;
  max-width: 600px;
}

.payment .modal-content {
  border-radius: 20px;
}

.plans__back-btn,
.plans__back-btn:link,
.plans__back-btn:active,
.plans__back-btn:visited {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  position: absolute;
  top: 2.5%;
  right: 2.5%;
}
.plans__back-btn .link,
.plans__back-btn:link .link,
.plans__back-btn:active .link,
.plans__back-btn:visited .link {
  text-decoration: underline !important;
}
.settings {
  background-color: #f5f5f7;
  min-height: 100vh !important;
  padding-top: 30px !important;
}

.settings__title {
  font-size: 24px;
  line-height: 20px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #222222;
  text-transform: uppercase;
}

.settings__tab {
  margin-top: 50px;
  margin-left: 30px;
  width: 100%;
  max-width: 700px;
}

.settings__tab-title {
  font-size: 22px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  letter-spacing: 0px;
  color: #222222;
}

.settings__tab-body {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 20px #ff643a0d;
  border-radius: 10px;
}

.settings__tab-body-title {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: bold;
  color: #ff643a;
}

.settings__change-password {
  margin-top: 10px;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
}

.input-container {
  width: calc(50% - 10px);
  min-width: 150px;
  position: relative;
}

.input-container label,
.input-container input {
  display: block !important;
  width: 100%;
}
.input-container input {
  border: none;
  background: #f5f5f7;
  border-radius: 5px;
  padding: 10px;
}
.input-container input::placeholder {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  color: #7c7c7c;
  text-transform: capitalize;
}
.input-container label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #222222;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.save-btn {
  background: transparent
    linear-gradient(98deg, #222222 0%, #7c7c7c 45%, #7c7c7c 100%);
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
  height: 40px;
  width: 226px;
  margin-top: 30px;
}

.settings .save-btn {
  margin-left: 30px;
}

.input-container .input-error {
  color: #f40000;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  text-transform: capitalize;
}

/*Media Queries Of Website*/

/*Small Laptops*/
@media screen and (max-width: 992px) {
  .settings__tab {
    max-width: unset;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .settings .save-btn {
    margin-left: 0;
  }
  .side-bar-card {
    padding: 0 22px;
  }
  .custom-container1 {
    margin: 100px auto 100px auto;
  }
  .plans-page {
    padding-top: 100px !important;
  }

  .custom-container2 {
    max-width: 500px !important;
    margin: 80px auto 80px auto;
  }
  section.left-sidebar {
    width: 25%;
  }
  .sidebar-menu ul li a {
    padding: 13px 10px;
  }
  section.page-content {
    float: right;
    width: 75%;
    padding: 0px 30px;
  }
  .action-head h3 {
    font-size: 30px;
    font-weight: 700;
    max-width: 400px;
    margin: auto;
    letter-spacing: 0.6px;
  }
  .action-content {
    text-align: center;
    display: block;
    margin: 50px 0px 0px 0px;
  }
  .profile-image-uploader {
    max-width: 300px;
  }
  .custom-margin1 {
    margin-top: 40px;
  }

  .about-form {
    width: 100%;
    margin-bottom: 30px;
  }
  .inline-field1 {
    margin-bottom: 20px !important;
  }

  .colors, .logo-upload{
    margin-right: 0 !important;
    margin-left: 0 !important;
    
  }
 .logo-upload{
  margin-top: 12px;
 }
}

/*Tablet Devices*/
@media screen and (max-width: 768px) {
  section.left-sidebar {
    width: 100%;
    position: relative;
    
    height: unset;
  }
  .sidebar-menu ul li a {
    padding: 10px 7px;
  }
  .sidebar-menu ul li {
    margin-bottom: 12px;
  }
  .sidebar-menu {
    width: 100%;
    display: none;
  }
  .sidebar-menu--visible {
    display: block;
  }
  .sidebar-menu ul li a img {
    width: 30px;
    margin-right: 8px;
    vertical-align: middle;
  }
  .logout-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 0px;
    padding-left: 0px;
    background: black;
  }
  .navbar-handler {
    display: block;
  }
  .custom-container1 {
    margin: 125px auto 125px auto;
    padding: 0 33px !important;
  }
  .plans-page {
    padding: 125px 33px 0 33px !important;
  }
  .custom-container2 {
    max-width: 500px !important;
    margin: 80px auto 80px auto;
    padding: 0 33px !important;
  }
  .select-card-page {
    padding-top: 150px !important;
  }
  .logo-2 {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0px 22px;
  }
  .logo-2 h1 {
    color: white;
    text-align: center;
    font-size: 28px;
    letter-spacing: 1px;
    float: left;
  }
  .navbar-handler {
    float: right;
    display: block;
  }

  .navbar-handler img {
    width: 26px;
  }
  .navbar-handler {
    margin-top: 10px;
  }

  section.page-content {
    float: left;
    width: 100%;
    padding: 50px 30px;
  }
  .action-content {
    text-align: center;
    display: block;
    margin: 0px;
  }
  .action-head h3 {
    font-size: 30px;
    font-weight: 700;
    max-width: 400px;
    margin: auto;
    letter-spacing: 0.6px;
  }

  .action-options a {
    width: 44%;
    margin: 0px 1.6% 30px 1.6% !important;
    font-size: 20px;
  }
  .action-options {
    display: block;
    text-align: center;
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  section.page-content {
    background-size: cover;
    background-position: top center;
    background-position-x: 26%;
  }
  .custom-margin1 {
    margin-top: 0px;
  }
  .profile-image-uploader {
    max-width: 300px;
  }
  .form-field-submit2 {
    text-align: left;
    float: left;
    width: 100%;
  }
  .submit-btn3,
  .submit-btn4 {
    width: 160px;
    margin: 0px 10px 0px 0px !important;
    font-size: 18px;
  }
  .about-form {
    float: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .custom-form2 {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .custom-margin2 {
    margin-top: 0px;
  }
  .profile-name {
    float: left;
    width: 55%;
    padding-top: 0px;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  .inline-field1 {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .profile-name h4 {
    margin-bottom: 0px !important;
  }
  .profile-image-uploader {
    max-width: 100%;
  }
  .profile-wrapper2 {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
  }
}

/*Mobile Devices*/
@media screen and (max-width: 519px) {
  .input-container {
    width: 100%;
    margin-top: 20px;
  }
  .form-container {
    margin-top: 0;
  }
  .field-style5 {
    font-size: 26px;
    line-height: 32px;
    height: 75px;
  }
  .field-style7 {
    font-size: 14px;
    line-height: 20px;
    height: 65px;
  }

  .logo1 h1 {
    font-weight: 700;
    font-size: 35px;
    letter-spacing: 1px;
  }
  .sec-head1 h3 {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 40px;
  }
  section.bg-1 {
    background-size: cover;
    min-height: 100vh;
    background-position: top right;
    background-position-x: 19%;
  }
  .navbar-handler {
    display: block;
  }
  .logo-2 {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0px 22px;
  }
  .logo-2 h1 {
    color: white;
    text-align: center;
    font-size: 28px;
    letter-spacing: 1px;
    float: left;
  }
  .navbar-handler {
    float: right;
    display: block;
  }

  .navbar-handler img {
    width: 26px;
  }
  .navbar-handler {
    margin-top: 10px;
  }
  .sidebar-menu ul li a {
    padding: 10px 7px;
  }
  .logout-wrapper {
    position: static;
    bottom: 25px;
    left: 0px;
    width: 100%;
    text-align: center;
    float: left;
    width: 100%;
    margin-bottom: 0px;
    padding-left: 0px;
    position: fixed;
    bottom: 0px;
    background: black;
  }

  .sidebar-menu {
    width: 100%;
    display: none;
  }
  .sidebar-menu--visible {
    display: block;
  }
  .sidebar-menu ul li a img {
    width: 30px;
    margin-right: 8px;
    vertical-align: middle;
  }
  section.page-content {
    float: left;
    width: 100%;
    padding: 50px 30px;
  }
  .action-content {
    text-align: center;
    display: block;
    margin: 0px;
  }
  .action-head h3 {
    font-size: 30px;
    font-weight: 700;
    max-width: 400px;
    margin: auto;
    letter-spacing: 0.6px;
  }

  .action-options a {
    width: 100%;
    margin: 0px 2% 30px 2% !important;
    padding: 20px 20px 30px 20px !important;
  }
  .action-options {
    display: block;
    text-align: center;
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  section.page-content {
    background-size: cover;
    background-position: top center;
    background-position-x: 26%;
    min-height: 0px;
  }
  .about-form {
    width: 100%;
    margin-bottom: 30px;
  }
  .profile-image-uploader {
    max-width: 100%;
  }

  .custom-margin1 {
    margin-top: 0px;
  }
  .submit-btn3,
  .submit-btn4 {
    width: 150px;
    margin: 0px 10px 12px 0px !important;
    font-size: 16px;
  }
  .form-field-submit2 {
    text-align: left;
    float: left;
    width: 100%;
  }
  .custom-margin2 {
    margin-top: 0px;
  }
  .custom-form2-head h3 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
  }
  .custom-form2 {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-name {
    float: left;
    width: 58%;
    padding-top: 0px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  .profile-name h4 {
    color: white;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: normal;
    margin-bottom: 10px;
    margin: 0px;
    line-height: 30px;
  }
  .profile-name h6 {
    color: silver;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    padding-right: 10px;
  }
  .social-fields ul li a {
    background: #f5f5f5;
    padding: 0px;
    border-radius: 100%;
    font-size: 20px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .social-fields ul li {
    display: inline-block;
    vertical-align: top;
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .profile-image5 {
    float: left;
    width: 42%;
  }
  .profile-image5 img {
    height: 100%;
    width: auto;
  }
  .inline-field1 {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .saved-box5 div {
    float: left;
    width: 100%;
  }
  .saved-box5 div:nth-child(2) {
    margin-top: 2px;
    margin-bottom: 6px;
  }
  .dual-field .form-field2 .field-style2 {
    width: 100% !important;
  }

  .dual-field .form-field2 {
    justify-content: space-between;
    display: block;
  }
  .dual-field .form-field2 .field-style2:nth-child(2) {
    width: 200px !important;
    margin-top: 10px !important;
    height: 30px !important;
    padding: 0px 2px !important;
  }
  .profile-header {
    float: left;
    width: 100%;
    background: black;
    padding: 20px 12px 150px 12px;
    border-radius: 8px 8px 0px 0px;
  }
  .profile-overview {
    float: left;
    width: 100%;
    background: black;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-top: -125px;
    overflow: hidden;
  }
  .custom-pad2 {
    padding: 0px 5px;
  }
  .profile-btn1,
  .profile-btn2 {
    font-size: 14px;
  }
  .about-para p {
    color: black;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
  div.profile-buttons {
    margin-bottom: 20px !important;
  }
  .saved-box5 div {
    width: 100% !important;
    text-align: left !important;
    margin: 6px 0px !important;
  }

  .social-popup1 .modal-content {
    padding: 25px 10px;
  }
  .contacts-save-btn {
    width: 90%;
  }
  .contact-delete-icon {
    top: unset !important;
    right: 10% !important;
    transform: translateX(100%) !important;
    bottom: 21px;
  }
}
.custom-form2-head h3 {
  font-weight: 800;
}
.submit-btn5,
.submit-btn4 {
  font-weight: 700;
}
.page-header h3,
.profile-name h4 {
  font-weight: normal;
}
.action-options a,
.submit-btn3,
.profile-btn1,
.profile-btn2,
.saved-box5 div h5 {
  font-weight: 800;
}

button.submit-btn3 {
  font-weight: 700 !important;
}
.profile-name h4 {
  font-weight: 800;
}
.saved-box5 div h5 {
  font-weight: 700;
}
.page-header h3 {
  font-weight: 800;
}

:root {
  --chakra-space-3: 0.88rem;
  --chakra-space-4: 1rem;
  --chakra-space-11: 0.75rem;
  --chakra-sizes-14: 3.5rem;
  --text-white: #ffffff;
  --chakra-colors-brand-gray-300: #d4d4d4;
  --chakra-shadows-brand-md: 0px 6px 20px rgba(0, 0, 0, 0.12);
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--chakra-colors-brand-gray-300);
}

h1,
h2,
h3,
p {
  margin: 0;
}
h1 {
  font-size: 45px;
  line-height: 48px;
  /* font-size: 8vmin;
    line-height: 8.5vmin; */
}
h3 {
  font-size: 18px;
}
#main {
  max-width: 576px;
  margin: 0 auto;
  padding-left: var(--chakra-space-4);
  padding-right: var(--chakra-space-4);
  position: relative;
  box-sizing: border-box;
}
.head-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: all 250ms ease 0s;
  padding: 23px 0;
}
.head-box img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.head-box-logo-container {
  width: 92px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.head-box a:hover {
  background-color: rgb(255 255 255 / 16%);
}
.head-box::after {
  content: "";
  position: absolute;
  top: 0px;
  height: 250px;
  z-index: -1;
  left: 0px;
  right: 0px;
  background: var(--head-box-bg-color);
}
.head-box a {
  color: #fff;
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, 0.48);
  border-radius: 0.5rem;
  width: 127px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  font-weight: 500;
  opacity: 0.9;
}
.bottom-head {
  display: flex;
  border-radius: 1.3rem;
  color: var(--text-white);
  overflow: hidden;
  align-items: flex-end;
  background-color: #010101;
  filter: drop-shadow(var(--chakra-shadows-brand-md));
  height: 341px;
  max-width: 544px;
}
.bottom-head .col-6 {
  height: 100%;
}
.bottom-head-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
/* .bottom-head .col-6:first-child, */
.exchange-contact .col-6:first-child {
  max-width: 244px;
  width: 100%;
}
/* .bottom-head .col-6:last-child, */
.exchange-contact .col-6:last-child {
  width: calc(100% - 244px);
}
.bottom-head .text-start {
  padding: 0 20px;
  padding-bottom: 40px;
  padding-top: 40px;
  overflow-y: auto;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.bottom-head .text-start h1 {
  margin-bottom: 10px;
  font-weight: bold;
  margin-top: auto;
}
.bottom-head .text-start p {
  font-size: 23px;
  color: #a1a1a1;
  font-weight: 500;
  /* font-size: 4.1vmin;
    line-height: 5.05vmin; */
}
.exchange-contact {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.exchange-contact p,
.submit-btn input {
  width: calc(100% - 5px);
  color: #010101;
  text-decoration: none;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
  padding-top: var(--chakra-space-3);
  padding-bottom: var(--chakra-space-3);
  text-align: center;
  display: block;
  font-size: 14px;
  line-height: 17px;
  font-weight: 800;
  transition: all 250ms ease 0s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  user-select: none;
  cursor: pointer;
}
.download-contact-btn {
  width: calc(100% - 5px);
  color: #010101;
  text-decoration: none;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
  padding-top: var(--chakra-space-3);
  padding-bottom: var(--chakra-space-3);
  text-align: center;
  display: block;
  font-size: 14px;
  line-height: 17px;
  font-weight: 800;
  transition: all 250ms ease 0s;
  -webkit-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.download-contact-btn:hover {
  background-color: #010101;
  color: var(--text-white);
}
.exchange-contact .col-6:first-child p:hover {
  background-color: #010101;
  color: var(--text-white);
}
.exchange-contact .col-6:last-child p,
.submit-btn input {
  margin-left: auto;
  background-color: #ff643a;
  color: var(--text-white);
  border: none;
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  user-select: none;
}
.submit-btn input {
  background: #ff643a;
  height: 3rem;
}
.exchange-contact .col-6:last-child p:hover,
.submit-btn input:hover {
  background-color: #ff3b06;
}
.social-box {
  margin-top: 32px;
}
.contact-info {
  margin-top: 30px;
  margin-bottom: 20px;
}
.social-box ul {
  display: grid;
  grid-gap: var(--chakra-space-11);
  grid-template-columns: repeat(auto-fill, 59px);
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-top: 16px;
}
.social-box ul li {
  position: relative;
  transition: all 0.1s ease 0s;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.social-box ul li a {
  display: flex;
  border-radius: 0.75rem;
  background: #f5f5f5;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: var(--chakra-space-4);
  width: 59px;
  height: 59px;
  box-sizing: border-box;
}
.social-box ul li a:hover {
  background: #d4d4d4;
}
.social-box ul li img {
  width: 100%;
}

.contact-info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}
.contact-info ul li {
  display: flex;
  align-items: center;
  width: 100%;
}

.contact-info ul li .icon {
  width: var(--chakra-sizes-14);
  text-align: center;
}

.contact-info ul li a {
  text-decoration: none;
  color: #1a202c;
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 3.5rem);
  width: calc(100% - var(--chakra-sizes-14));
  padding: 16px 0;
  word-break: break-all;
  align-items: center;
}
.contact-info ul li a::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 1px;
  background-color: #e3e3e3;
}
.text-start {
  text-align: left;
}
.text-end {
  text-align: right;
}
.text-center {
  text-align: center;
}

/* Popup */
.contact_rob {
  font-family: "Raleway", sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #0000007a;
  align-items: flex-end;
}

.contact_rob header {
  padding: 1rem;
  padding-top: 2rem;
}
.contact_rob h3 {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 29px;
  font-weight: 800;
  max-width: calc(100% - 25px);
}
.contact_rob header img {
  margin-right: 0.5rem;
}
.close {
  background-color: #f5f5f5;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 999;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.close:hover {
  background-color: rgb(0 0 0 / 6%);
}
.contact_rob .modal-body {
  padding: 1rem;
  padding-top: 0;
}
.form-field {
  border: none;
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
  font-size: 1rem;
  position: relative;
  appearance: none;
  transition: all 0.2s ease 0s;
  height: 2.5rem;
  background: transparent;
}
.field-error .form-field {
  border-color: #f40000;
}
.field-label label {
  z-index: 1;
  font-size: 1rem;
  color: #a1a1a1;
  /* }
.field-label { */
  position: absolute;
  top: 27px;
  transition: 0.2s top, 0.2s left;
}
.phone-label label {
  left: 42px;
}
.field-name input + p {
  font-size: 0.875rem;
  color: #a1a1a1;
  font-weight: 400;
  margin-top: 0.5rem;
}
.field-name .error,
.field-error .field-label label {
  color: #f40000;
}
.hide {
  display: none !important;
}
.focus-in .field-label label {
  color: #ff643a;
}

.field-box {
  margin-top: 1.5rem;
  padding-top: 1rem;
  position: relative;
}
.contact_rob .modal-body form .field-box:first-child {
  margin-top: 0.5rem;
}
.form-field:focus {
  outline: none;
  border-color: #ff643a;
}
.submit-btn input {
  width: 100%;
  margin-top: 2.5rem;
  cursor: pointer;
  margin-bottom: 10px;
}

@media only screen and (max-width: 604px) {
  .contact-profile-page .bottom-head {
    height: calc((100vw - 60px) * 0.627);
  }
}

@media only screen and (max-width: 576px) {
  .bottom-head {
    height: calc((100vw - 32px) * 0.627);
  }
  .bottom-head .col-6:first-child,
  .bottom-head img,
  .exchange-contact .col-6:first-child,
  .exchange-contact .col-6:last-child {
    width: 100%;
  }
  h1 {
    line-height: 100%;
    font-size: 7.8vw;
  }
  .bottom-head .text-start p {
    font-size: 4vw;
  }
  .bottom-head .text-start {
    padding-top: 7vw;
    padding-bottom: 7vw;
  }
  .head-box::after {
    height: 50vw;
  }
  .contact_rob h3 {
    padding-right: 40px;
  }
}
.col-6 {
  flex: 1;
}
.container {
  padding: 0;
}
.modal {
  padding: 0 !important;
}
.contact_rob .modal-dialog {
  position: fixed;
  bottom: 0;
  margin: 0;
  max-width: 576px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.contact_rob .modal-content {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border: none;
}
.custom-margin1 {
  margin-top: 70px;
}
.form-field-submit2 {
  text-align: left;
  float: none !important;
  display: inline-block !important;
  width: auto;
}
.error-message {
  color: #f40000;
}
.success-message {
  color: #00b22d;
}
.form-field-error {
  border-color: #f40000;
}
.select-card-page {
  padding-top: 100px;
  position: relative;
  background: linear-gradient(110deg, #000000 70%, #ff643a 300%);
  min-height: 100vh;
}
.select-card-page .logo1 {
  position: absolute;
  width: 110px;
  top: 2.5%;
  left: 2.5%;
}
.select-card-title {
  text-align: center;
  font-size: 32px;
  line-height: 37px;
  font-weight: bold;
}
.cards-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  margin-top: 25px;
}
.card-item {
  position: relative;
}
.card-img {
  width: 100%;
  max-width: 275px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.search-area {
  text-align: center;
  margin-top: 25px;
}

.card-name {
  color: white;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-edit-icon,
.card-save-icon,
.card-cancel-icon {
  margin-left: 7px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.card-save-icon {
  fill: green;
}

.card-cancel-icon {
  fill: red;
}

.card-edit-field,
.cards-search-field {
  background-color: #222222;
  border-radius: 2px;
  color: white !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  border: none;
  padding: 3px 10px;
}

.cards-search-field {
  width: 95%;
  max-width: 200px;
}

input.card-edit-field::placeholder,
input.cards-search-field::placeholder {
  color: #7c7c7c !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.field-style1 {
  background-color: #222222;
  border-radius: 10px;
  color: white !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  border: none;
  height: 46px;
  width: 100%;
  font-weight: normal;
  padding: 0px 22px;
}

.list-field {
  position: relative;
}
.remove-field {
  position: absolute;
  right: -25px;
  top: 20px;
  transform: translateY(-50%);
  color: rgb(141, 141, 141);
  font-size: 18px;
  cursor: pointer;
  user-select: none;
}
.contact-delete-icon {
  display: inline-block;
  color: #f40000;
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  cursor: pointer;
}
.preview-profile {
  font-family: "Raleway", sans-serif;
}
.preview-profile-subtitle {
  font-weight: 800;
}
.lead-form-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.custom-toast {
  position: fixed !important;
  margin-right: 20px;
  margin-top: 20px;
  z-index: 1000;
}
.lead-form-page .head-box::after {
  height: 115px;
}
.lead-form-activate-page {
  margin-top: 100px;
}
.leadform-title {
  font-size: 24px;
  line-height: 29px;
  font-weight: 800;
  margin-top: 25px;
}
.preview-profile-about {
  margin-top: 32px;
}
.preview-profile-about-paragraph {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 16px;
  color: rgb(49, 49, 49);
}
.forgot-password-success {
  margin-bottom: 16px;
  font-weight: 500;
  color: green;
}
button:disabled {
  background-color: grey;
}
button:disabled:hover {
  background-color: grey;
}
.reset-password-login,
.reset-password-login:hover,
.reset-password-login:active,
.reset-password-login:link {
  color: #ff643a;
  letter-spacing: 0.4px;
  font-weight: 500;
  padding-left: 5px;
}
.reset-password-success {
  font-size: 20px;
  font-weight: 500;
}
.card-selector {
  border: 1px solid rgb(160, 160, 160);
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 25px;
}
.contact-profile-page .remove-image {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #ff643a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-profile-page .remove-image:hover {
  background-color: black;
}
.contact-profile-page .remove-image .remove-image-icon {
  width: 22px;
  height: 22px;
}
.contact-profile-page .remove-image .remove-image-icon path {
  fill: white;
}
.qr-page {
  margin-top: 100px;
}
.qr-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-bottom: 35px;
}
.export-btn {
  background: rgb(0, 153, 0);
  border: none;
  color: white;
  font-size: 14px;
  padding: 6px 15px;
  letter-spacing: 0.6px;
  font-weight: 400;
  border-radius: 6px;
  text-decoration: none;
  margin-left: 15px;
}
.field-rows {
  margin: 35px 0;
}
.field-rows .field-box {
  margin-top: 0;
  flex: 1;
}
.field-type-selector {
  border: none;
  border-bottom: 1px solid rgb(160, 160, 160);
  border-radius: 4px;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
}
.field-row {
  display: flex;
  align-items: flex-end;
}
.field-checkbox {
  user-select: none;
  margin-bottom: 3px;
}
.field-icon-container {
  width: 18px;
  height: 18px;
  margin-bottom: 10px;
  margin-left: 15px;
  cursor: pointer;
}
.field-icon-container path {
  fill: red;
}
.field-icon {
  width: 100%;
  height: 100%;
}
.powered-by {
  margin-top: auto;
  font-size: 14px;
  text-align: right;
  font-weight: 600;
}
.powered-by a,
.powered-by a:hover,
.powered-by a:active,
.powered-by a:link {
  text-decoration: none;
  color: #ff643a;
}
.preview-profile .container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.PhoneInputInput {
  border: none;
}
.field-style2 .PhoneInput {
  font-weight: 600;
}
.PhoneInputCountryIcon--border {
  box-shadow: none !important;
}
.PhoneInput--focus .PhoneInputCountrySelectArrow {
  border-color: #ff643a;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .custom-margin1 {
    margin-top: 0px;
  }
}
@media screen and (max-width: 425px) {
  .cards-list {
    width: 90%;
  }
  .contact-prof-title {
    padding: 30px 15px;
  }
  .field-style5 {
    font-size: 20px;
    line-height: 25px;
    height: 65px;
  }
  .field-style7 {
    font-size: 12px;
    line-height: 15px;
    height: 55px;
  }
}

.stats-item {
  border-color: silver;
  width: 6.5rem;
  height: 6.5rem;
}

.stats-item__value,
.stats-item__label {
  color: #505050;
}

.contact-profile-appearance-header {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
}